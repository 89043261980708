<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Note</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="note-form">
          <v-text-field
            v-model="fields.work_group_id"
            outlined
            dense
            :error="errors.hasOwnProperty('work_group_id')"
            :error-messages="errors['work_group_id']"
            v-show="false"
          ></v-text-field>

          <v-select
            label="Note Type"
            v-model="fields.type"
            :items="types"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('type')"
            :error-messages="errors['type']"
          ></v-select>

          <v-textarea
            label="Note *"
            v-model="fields.note_text"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('note_text')"
            :error-messages="errors['note_text']"
          ></v-textarea>

          <v-menu
            v-model="completion_date_picker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Key Date"
                v-model="dateFormatted"
                readonly
                v-bind="attrs"
                v-on="on"
                background-color="white"
                outlined
                dense
                :error="errors.hasOwnProperty('key_date')"
                :error-messages="errors['key_date']"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fields.key_date"
              @input="completion_date_picker = false"
            ></v-date-picker>
          </v-menu>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="note-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    groupId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      completion_date_picker: null,
      fields: {
        note_text: null,
        key_date: null,
        work_group_id: null,
        type: null,
      },
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},

      types: ["Client Comms", "Event", "Update"],
    };
  },

  computed: {
    dateFormatted() {
      return this.formatDate(this.fields.key_date);
    },
  },

  methods: {
    openForm: function (note = null) {
      this.fields.work_group_id = this.groupId ? this.groupId : null;

      if (note !== null) {
        this.note = note;
        this.fields.note_text = note.note_text;
        this.fields.key_date = note.key_date;
        this.fields.type = note.type;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        chunkId: this.$route.params.chunkId,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.noteId = this.note.id;
      }

      this.$store
        .dispatch("workload/saveWorkChunkNote", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields = {
        note_text: null,
        key_date: null,
        work_group_id: null,
        type: null,
      };
    },
  },
};
</script>
