<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ isEditing ? "Edit" : "New" }} Block</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <v-form @submit.prevent="saveForm" method="post" id="work-chunk-form">
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                label="Name *"
                type="text"
                v-model="fields.name"
                outlined
                dense
                :error="errors.hasOwnProperty('name')"
                :error-messages="errors['name']"
              ></v-text-field>
            </v-col>

            <v-col cols="4" class="pb-0 pt-0">
              <v-switch
                v-model="fields.is_internal"
                :error="errors.hasOwnProperty('is_internal')"
                :error-messages="errors['is_internal']"
                class="mt-1"
                label="Is Internal?"
              ></v-switch>
            </v-col>
            <v-col cols="8" class="pb-0 pt-0">
              <v-select
                v-if="fields.is_internal == false"
                v-on:change="send"
                label="Customer *"
                :items="filteredCustomers"
                v-model="fields.customer_id"
                item-text="name"
                item-value="id"
                :return-object="false"
                outlined
                dense
                @change="changeGroup()"
                :disabled="!!customerId"
                :error="errors.hasOwnProperty('customer_id')"
                :error-messages="errors['customer_id']"
              >
                <template v-slot:prepend-item>
                  <div class="pa-3">
                    <v-text-field
                      label="Search"
                      type="text"
                      v-model="searchTerm"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </div>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" class="pb-0 pt-0">
              <v-select
                label="Work Group(s)"
                :items="filteredGroup"
                v-model="fields.groups"
                item-text="name"
                item-value="id"
                :return-object="false"
                multiple
                outlined
                dense
                :error="errors.hasOwnProperty('groups')"
                :error-messages="errors['groups']"
              >
                <template v-slot:prepend-item>
                  <div class="pa-3">
                    <v-text-field
                      label="Search"
                      type="text"
                      v-model="searchTermGroup"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </div>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" class="pb-0 pt-0">
              <v-select
                label="Assigned to"
                :items="filteredTeam"
                v-model="fields.assigned"
                item-text="full_name_from_team"
                item-value="id"
                :return-object="false"
                multiple
                outlined
                dense
                @change="assignHasChanged()"
                :error="errors.hasOwnProperty('assigned')"
                :error-messages="errors['assigned']"
              >
                <template v-slot:prepend-item>
                  <div class="pa-3">
                    <v-text-field
                      label="Search"
                      type="text"
                      v-model="searchTermTeam"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </div>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" class="pb-0 pt-0">
              <v-textarea
                label="Description"
                v-model="fields.description"
                outlined
                dense
                :error="errors.hasOwnProperty('description')"
                :error-messages="errors['description']"
              ></v-textarea>
            </v-col>
            <v-col cols="6" class="pb-0 pt-0">
              <v-select
                label="Owner"
                :items="teamMembers"
                v-model="fields.team_lead"
                item-text="full_name"
                item-value="id"
                :return-object="false"
                :clearable="true"
                outlined
                dense
                :error="errors.hasOwnProperty('team_lead')"
                :error-messages="errors['team_lead']"
              ></v-select>
            </v-col>
            <v-col cols="6" class="pb-0 pt-0">
              <v-select
                label="Type *"
                :items="types"
                outlined
                dense
                v-model="fields.type"
                :error="errors.hasOwnProperty('type')"
                :error-messages="errors['type']"
              ></v-select>
            </v-col>
            <v-col cols="6" class="pb-0 pt-0">
              <v-text-field
                label="Scheduled for"
                v-model="fields.to_do"
                type="date"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('to_do')"
                :error-messages="errors['to_do']"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pb-0 pt-0">
              <v-select
                label="Status *"
                :items="statuses"
                outlined
                dense
                v-model="fields.status"
                @change="statusHasChanged()"
                :error="errors.hasOwnProperty('status')"
                :error-messages="errors['status']"
              ></v-select>
            </v-col>
            <v-col cols="6" class="pb-0 pt-0">
              <v-menu
                v-model="completion_date_picker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Deadline"
                    v-model="dateFormatted"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    :error="errors.hasOwnProperty('expected_completion_date')"
                    :error-messages="errors['expected_completion_date']"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fields.expected_completion_date"
                  @input="completion_date_picker = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" class="pb-0 pt-0">
              <v-switch
                v-model="fields.locked"
                :error="errors.hasOwnProperty('locked')"
                :error-messages="errors['locked']"
                class="mt-1"
                label="Locked"
              ></v-switch>
            </v-col>
            <v-col cols="6" class="pb-0 pt-0">
              <v-text-field
                label="Rate"
                type="number"
                v-model="fields.rate"
                prefix="£"
                min="0.00"
                step="1.00"
                outlined
                dense
                :error="errors.hasOwnProperty('rate')"
                :error-messages="errors['rate']"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pb-0 pt-0">
              <v-text-field
                label="Value"
                type="number"
                v-model="fields.value"
                prefix="£"
                min="0.00"
                step="1.00"
                outlined
                dense
                :error="errors.hasOwnProperty('value')"
                :error-messages="errors['value']"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pb-0 pt-0">
              <v-text-field
                label="Priority"
                type="number"
                v-model="fields.priority"
                min="0"
                step="1"
                outlined
                dense
                :error="errors.hasOwnProperty('priority')"
                :error-messages="errors['priority']"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="work-chunk-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    groups: {
      type: Array,
      required: true,
    },
    customers: {
      type: Array,
      required: true,
    },
    teamMembers: {
      type: Array,
      required: true,
    },
    customerId: {
      type: Number,
      required: false,
    },
    userId: {
      type: Number,
      required: false,
    },
    fromTasking: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data() {
    return {
      dialog: false,
      completion_date_picker: null,
      loading: false,
      errors: {},
      fields: {
        name: null,
        description: null,
        customer_id: null,
        status: "Open",
        value: null,
        rate: null,
        locked: false,
        expected_completion_date: null,
        assign_has_changed: false,
        status_has_changed: false,
        to_do: null,
        is_internal: false,
        team_lead: null,
        type: null,
        groups: null,
        assigned: null,
        priority: 100,
      },
      searchTerm: "",
      searchTermGroup: "",
      searchTermTeam: "",
      isEditing: false,
      fromWorkGroup: false,
      workChunk: {},
      statuses: ["Open", "Closed", "Deleted", "Completed"],
      types: ["Project", "Ad-hoc", "Unknown", "Contract", "Diary"],
    };
  },

  created() {
    if (this.customerId) {
      this.fields.customer_id = this.customerId;
    }

    if (this.userId) {
      this.fields.team_lead = this.userId;
    } else {
      this.fields.team_lead = this.currentTeamMember.id;
    }
  },

  watch: {
    groups(value) {
      this.groups = value;
    },
  },

  computed: {
    currentTeamMember() {
      return this.$store.state.workload["user"];
    },

    dateFormatted() {
      return this.formatDate(this.fields.expected_completion_date);
    },

    filteredCustomers() {
      let customers = this.customers;
      if (this.searchTerm !== "" || this.searchTerm !== null) {
        customers = customers.filter((c) => {
          const name = c.name.toLowerCase();
          const shortcode = c.shortcode ? c.shortcode.toLowerCase() : "";
          return (
            name.includes(this.searchTerm.toLowerCase()) ||
            shortcode.includes(this.searchTerm.toLowerCase())
          );
        });
      }

      return customers;
    },

    filteredGroup: {
      get() {
        let groups = this.groups;
        if (this.searchTermGroup !== "" || this.searchTermGroup !== null) {
          groups = groups.filter((c) => {
            const name = c.name.toLowerCase();
            return name.includes(this.searchTermGroup.toLowerCase());
          });
        }
        return groups;
      },

      set(value) {
        return value;
      },
    },

    filteredTeam() {
      let teamMembers = this.teamMembers;

      if (
        (this.searchTermTeam !== "" || this.searchTermTeam !== null) &&
        typeof this.searchTermTeam === "string"
      ) {
        teamMembers = teamMembers.filter((m) => {
          const name = m.full_name.toLowerCase();
          const email = m.email.toLowerCase();
          return (
            name.includes(this.searchTermTeam.toLowerCase()) ||
            email.includes(this.searchTermTeam.toLowerCase())
          );
        });
      }

      return teamMembers;
    },
  },

  methods: {
    assignHasChanged() {
      this.fields.assign_has_changed = true;
    },

    statusHasChanged() {
      this.fields.status_has_changed = true;
    },

    send: function () {
      const appId = this.$route.params.id;
      const customerId = this.fields.is_internal
        ? null
        : this.fields.customer_id;

      this.loading = true;

      this.$store
        .dispatch("workload/loadCustomerGroups", {
          appId,
          customerId,
        })
        .then((res) => {
          this.loading = false;
          this.groups = res.data.groups;
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    changeGroup: function () {
      const customerId = this.fields.customer_id;
      let groups = this.groups;
      groups = groups.filter((c) => {
        return c.customer_id == customerId;
      });

      return groups;
    },

    openForm: function (workChunk = null, workGroup = null) {
      if (workChunk !== null) {
        this.fields.team_lead = null;
        this.workChunk = workChunk;
        this.fields.name = workChunk.name;
        this.fields.description = workChunk.description;
        this.fields.status = workChunk.status;
        this.fields.type = workChunk.type;
        this.fields.value = workChunk.value;
        this.fields.rate = workChunk.rate;
        this.fields.priority = workChunk.priority;
        this.fields.expected_completion_date =
          workChunk.expected_completion_date;
        this.fields.to_do = workChunk.to_do;
        this.fields.groups = workChunk.groups.map((g) => g.id);
        this.fields.assigned = workChunk.assigned.map((g) => g.id);
        this.fields.customer_id = workChunk.customer_id;
        this.fields.is_internal = Boolean(Number(workChunk.is_internal));
        this.fields.locked = Boolean(Number(workChunk.locked));
        this.fields.team_lead = workChunk.team_lead;
        this.isEditing = true;

        if (!workGroup) {
          this.groups = this.groups.filter((c) => {
            return c.customer_id == this.fields.customer_id;
          });
        }
      }
      if (workGroup) {
        this.fields.groups = workGroup.id;
        this.fields.customer_id = workGroup.customer_id;
        this.fields.is_internal = workGroup.is_internal;

        this.fromWorkGroup = true;
        this.workGroup = workGroup;
      }
      this.dialog = true;
    },

    saveForm: function () {
      const appId = this.$route.params.id;

      this.loading = true;
      this.errors = {};

      let payload = {
        appId,
        fields: this.fields,
        isEditing: this.isEditing,
        fromWorkGroup: this.fromWorkGroup,
        fromTasking: this.fromTasking,
      };

      if (this.isEditing) {
        payload.id = this.workChunk.id;
      }

      let dispach = "workload/saveWorkChunk";

      this.$store
        .dispatch(dispach, {
          ...payload,
        })
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.completion_date_picker = null;
      this.loading = false;
      this.errors = {};
      this.fields = {
        name: null,
        description: null,
        customer_id: this.customerId ?? null,
        status: "Open",
        value: null,
        rate: null,
        locked: false,
        expected_completion_date: null,
        status_has_changed: false,
        assign_has_changed: false,
        to_do: null,
        priority: 100,
        is_internal: false,
        team_lead: this.currentTeamMember.id,
        type: null,
        groups: null,
        assigned: null,
      };
      this.isEditing = false;
      this.fromWorkGroup = false;
      this.workChunk = {};
    },
  },
};
</script>
