<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-form @submit.prevent="save" method="post" id="meta-form">
        <v-card-title>
          <span class="headline">Log Time</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Title"
                type="text"
                v-model="fields.title"
                outlined
                dense
                :error="errors.hasOwnProperty('title')"
                :error-messages="errors['title']"
                :hide-details="!errors.hasOwnProperty('title')"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Hours"
                type="number"
                v-model="fields.hours"
                outlined
                dense
                :error="errors.hasOwnProperty('hours')"
                :error-messages="errors['hours']"
                :hide-details="!errors.hasOwnProperty('minutes')"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Minutes"
                type="number"
                v-model="fields.minutes"
                outlined
                dense
                :error="errors.hasOwnProperty('minutes')"
                :error-messages="errors['minutes']"
                :hide-details="!errors.hasOwnProperty('minutes')"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                label="Chunk"
                :items="work_chunks"
                v-model="fields.chunk_id"
                item-text="name"
                item-value="id"
                :return-object="false"
                :disabled="editingWorkChunk"
                outlined
                dense
                :error="errors.hasOwnProperty('chunk_id')"
                :error-messages="errors['chunk_id']"
                :hide-details="!errors.hasOwnProperty('chunk_id')"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="datePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormatted"
                    label="Date"
                    readonly
                    outlined
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fields.date"
                  @input="datePicker = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Notes"
                type="text"
                v-model="fields.notes"
                outlined
                dense
                :error="errors.hasOwnProperty('notes')"
                :error-messages="errors['notes']"
                :hide-details="!errors.hasOwnProperty('notes')"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="reset">Close</v-btn>
          <v-btn
            color="accent"
            text
            type="submit"
            :loading="loading"
            form="meta-form"
            >Save</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    editingWorkChunk: {
      type: Boolean,
      default: false,
      required: false,
    },
    hasWorkloadAccess: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      datePicker: false,
      dialog: false,
      loading: false,
      isEditing: false,
      work_chunk: {},
      fields: {
        dialog: false,
        date: new Date().toISOString().slice(0, 10),
        notes: null,
        title: null,
        minutes: 0,
        hours: 0,
        is_internal: true,
        chunk_id: null,
        loading: false,
        errors: {},
      },
      errors: {},
    };
  },

  mounted() {
    if (this.hasWorkloadAccess) {
      this.loadChunks();
    }
  },

  methods: {
    open(work_chunk = null) {
      if (work_chunk !== null) {
        this.fields.chunk_id = work_chunk.id;
      }

      this.dialog = true;
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.task = {};
      this.fields.title = null;
      this.fields.notes = null;
      this.fields.date = new Date().toISOString().slice(0, 10);
      this.fields.minutes = 0;
      this.fields.hours = 0;
      this.fields.is_internal = true;
      this.fields.chunk_id = null;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      this.$store
        .dispatch("workload/logTimeChunk", payload)
        .then(() => this.reset())
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    loadChunks: function () {
      const appId = this.$route.params.id;
      Promise.all([
        this.$store.dispatch("tasking/recurringStore/loadTasks", { appId }),
      ]).then(() => {});
    },
  },

  computed: {
    dateFormatted() {
      return this.formatDate(this.fields.date);
    },

    work_chunks() {
      return this.$store.getters["workload/all"];
    },

    users() {
      return this.$store.state.team["teamMembers"];
    },
  },
};
</script>
