<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>{{ work_chunk.name }}</h1>
        </v-col>
        <v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                color="accent"
                class="mr-2"
                v-on="on"
                @click="$refs.workChunkForm.openForm(work_chunk)"
              >
                Edit Work Block
              </v-btn>
            </template>
            <span>Edit Work Block</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                class="mr-2"
                color="accent"
                v-on="on"
                @click="$refs.logTimeDialog.open(work_chunk)"
              >
                <v-icon>mdi-alarm-plus</v-icon>
              </v-btn>
            </template>
            <span>Log Time</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                :disabled="work_chunk.time_logs.length > 0"
                color="error"
                v-on="on"
                @click="
                  archiveWorkloadDialog = true;
                  archiveWorkloadData = item;
                "
              >
                Delete Block
              </v-btn>
            </template>
            <span>Delete Block</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col> </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Work Details</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.workChunkForm.openForm(work_chunk)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Customer</th>
                  <th>
                    <router-link
                      v-if="work_chunk.customer"
                      :to="{
                        name: 'customer-profile-basic',
                        params: { customerId: work_chunk.customer.id },
                      }"
                      >{{ work_chunk.customer.name }}</router-link
                    >
                    <div
                      v-if="work_chunk.is_internal == 1"
                      style="color: #cccccc"
                    >
                      VITAL HIKE
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>Status</th>
                  <th>
                    <v-chip
                      v-if="work_chunk.status == 'Open'"
                      class="ma-2"
                      :color="'green green--text lighten-5'"
                    >
                      {{ work_chunk.status }}
                    </v-chip>

                    <v-chip
                      v-else
                      class="ma-2"
                      :color="'red red--text lighten-5'"
                    >
                      {{ work_chunk.status }}
                    </v-chip>
                  </th>
                </tr>
                <tr>
                  <th>To Do Date</th>
                  <th>
                    <span v-if="work_chunk.to_do < this.theDate" color="red">
                      {{ work_chunk.formatted_dates.to_do }}
                    </span>

                    <span v-if="work_chunk.to_do >= this.theDate">
                      {{ work_chunk.formatted_dates.to_do }}
                    </span>
                  </th>
                </tr>
                <tr>
                  <th>Assigned to</th>
                  <th>
                    <div
                      v-for="(user, index) in work_chunk.assigned"
                      :key="`${index}-user`"
                      :value="user.id"
                    >
                      {{ user.full_name }}
                    </div>
                  </th>
                </tr>

                <tr>
                  <th>Due Date</th>
                  <td>
                    <span
                      v-if="work_chunk.expected_completion_date < this.theDate"
                      color="red"
                    >
                      {{ work_chunk.formatted_dates.expected_completion_date }}
                    </span>

                    <span
                      v-if="work_chunk.expected_completion_date >= this.theDate"
                    >
                      {{ work_chunk.formatted_dates.expected_completion_date }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>Description</th>
                  <td>{{ work_chunk.description }}</td>
                </tr>
                <tr>
                  <th>Groups</th>
                  <td>
                    <div v-for="item in work_chunk.groups" v-bind:key="item.id">
                      <span>{{ item.name }}</span>
                    </div>
                  </td>
                </tr>

                <tr>
                  <th>Created</th>
                  <td>
                    {{ formatDateTime(work_chunk.created_at, true) }} by
                    {{ work_chunk.user.full_name ?? "" }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Notes</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.noteDialog.openForm(form)"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-data-table
              :headers="noteTableHeaders"
              :items="work_chunk.notes"
              no-data-text="No Notes"
            >
              <template v-slot:item.created_at="{ item }">
                {{ formatDateTime(item.created_at, true) }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Time Logged</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.logTimeDialog.open(work_chunk)"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>

            <v-data-table
              :headers="tableHeaders"
              :items="work_chunk.time_logs"
              no-data-text="No time logs found"
            >
              <template v-slot:item.date="{ item }">
                {{ formatDateTime(item.date) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="openEditTimeLog(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      v-on="on"
                      @click="
                        archiveTimeLogDialog = true;
                        archiveTimeLogData = item;
                      "
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </template>
            </v-data-table>
            Total Hours: {{ work_chunk.total_time_logged.text }}
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="editLogTimeForm.dialog" max-width="600px">
      <v-card>
        <v-form @submit.prevent="editLogTime" method="post">
          <v-card-title>
            <span class="headline">Edit Log Time</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Title"
                  type="text"
                  v-model="editLogTimeForm.title"
                  outlined
                  dense
                  :error="editLogTimeForm.errors.hasOwnProperty('title')"
                  :error-messages="editLogTimeForm.errors['title']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  label="Chunk"
                  :items="work_chunks"
                  v-model="editLogTimeForm.chunk_id"
                  item-text="name"
                  item-value="id"
                  :return-object="false"
                  outlined
                  dense
                  :error="editLogTimeForm.errors.hasOwnProperty('chunk_id')"
                  :error-messages="editLogTimeForm.errors['chunk_id']"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Minutes"
                  type="number"
                  v-model="editLogTimeForm.minutes"
                  outlined
                  dense
                  :error="editLogTimeForm.errors.hasOwnProperty('minutes')"
                  :error-messages="editLogTimeForm.errors['minutes']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-menu
                  v-model="datePicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formatEditLogDate"
                      label="Date"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editLogTimeForm.date"
                    @input="datePicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  label="Notes"
                  type="text"
                  v-model="editLogTimeForm.notes"
                  outlined
                  dense
                  :error="editLogTimeForm.errors.hasOwnProperty('notes')"
                  :error-messages="editLogTimeForm.errors['notes']"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              text
              @click="editLogTimeForm.dialog = false"
              >Close</v-btn
            >
            <v-btn
              color="accent"
              text
              type="submit"
              :loading="editLogTimeForm.loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="archiveWorkloadDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete Workload</v-card-title>
        <v-card-text>
          Are you sure you want to delete
          {{ archiveWorkloadData ? archiveWorkloadData.name : "" }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="archiveWorkloadDialog = false"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="archiveWorkloadLoading"
            @click="archiveWorkload(archiveWorkloadData.id)"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="archiveTimeLogDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete Time Log</v-card-title>
        <v-card-text>
          Are you sure you want to delete
          {{ archiveTimeLogData ? archiveTimeLogData.name : "" }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="archiveTimeLogDialog = false"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="archiveTimeLogLoading"
            @click="archiveTimeLog(archiveTimeLogData.id)"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <NoteDialog
      ref="noteDialog"
      :groupId="work_chunk.groups.length > 0 ? work_chunk.groups[0].id : null"
    />
    <log-time-dialog ref="logTimeDialog" :editingWorkChunk="true" />
    <WorkChunkForm
      ref="workChunkForm"
      :groups="groups"
      :customers="customers"
      :teamMembers="teamMembers"
    />
  </div>
</template>

<script>
import NoteDialog from "./components/NoteDialog";
import WorkChunkForm from "./components/WorkChunkForm";
import LogTimeDialog from "/src/modules/tasking/views/components/LogTimeDialog.vue";
import moment from "moment";

export default {
  components: {
    WorkChunkForm,
    LogTimeDialog,
    NoteDialog,
  },

  data() {
    return {
      datePicker: false,
      theDate: moment().format("YYYY-MM-DD"),
      breadcrumbs: [],
      noteTableHeaders: [
        { text: "Date/Time", value: "created_at" },
        { text: "User", value: "user.full_name" },
        { text: "Note", value: "note_text" },
      ],
      tableHeaders: [
        { text: "Date Work Done", value: "date" },
        { text: "User", value: "user.full_name" },
        { text: "Title", value: "title" },
        { text: "Minutes", value: "minutes" },
        { text: "Date Submitted", value: "formatted_dates.created_at" },
        { text: "Actions", value: "actions", sortable: false, align: "end" },
      ],
      editLogTimeForm: {
        dialog: false,
        date: null,
        notes: null,
        title: null,
        chunk_id: null,
        minutes: null,
        loading: false,
        errors: {},
      },
      archiveTimeLogDialog: false,
      archiveTimeLogLoading: false,
      archiveTimeLogData: null,
      archiveWorkloadDialog: false,
      archiveWorkloadLoading: false,
      archiveWorkloadData: null,
    };
  },

  mounted() {
    this.setupBreadcrumbs(this.work_chunk.name);
  },

  computed: {
    work_chunks() {
      return this.$store.getters["workload/openWorkChunks"];
    },
    work_chunk() {
      return this.$store.state.workload["work_chunk"];
    },
    groups() {
      return this.$store.state.workload["groups"];
    },
    customers() {
      return this.$store.state.customers["customers"];
    },
    teamMembers() {
      return this.$store.state.team["teamMembers"];
    },

    formatEditLogDate() {
      return this.formatDateTime(this.editLogTimeForm.date);
    },
  },

  watch: {
    work_chunk: function (work_chunk) {
      this.setupBreadcrumbs(work_chunk.name);
    },
  },

  methods: {
    archiveWorkload: function (id) {
      const appId = this.$route.params.id;
      this.archiveWorkloadLoading = true;

      this.$store
        .dispatch("workload/archiveWorkload", {
          appId,
          id,
        })
        .then(() => {
          this.archiveWorkloadData = null;
          this.archiveWorkloadLoading = false;
          this.archiveWorkloadDialog = false;
        });
    },

    formatLogsDate: function (date) {
      return this.formatDate(date);
    },

    openEditTimeLog: function (logTime) {
      this.editLogTimeForm = {
        dialog: true,
        id: logTime.id,
        date: logTime.date,
        notes: logTime.notes,
        title: logTime.title,
        minutes: logTime.minutes,
        chunk_id: logTime.chunk_id,
        loading: false,
        errors: {},
      };
    },

    editLogTime: function () {
      const appId = this.$route.params.id;
      const date = this.editLogTimeForm.date;
      const notes = this.editLogTimeForm.notes;
      const title = this.editLogTimeForm.title;
      const minutes = this.editLogTimeForm.minutes;
      const logId = this.editLogTimeForm.id;
      const chunk_id = this.editLogTimeForm.chunk_id;

      this.editLogTimeForm.loading = true;

      this.$store
        .dispatch("workload/editLogTime", {
          appId,
          date,
          notes,
          title,
          minutes,
          logId,
          chunk_id,
        })
        .then(() => {
          this.editLogTimeForm = {
            dialog: false,
            date: null,
            notes: null,
            title: null,
            minutes: null,
            loading: false,
            errors: {},
          };
        })
        .catch((err) => {
          this.editLogTimeForm.errors = err.response.data.errors;
          this.editLogTimeForm.loading = false;
        });
    },

    archiveTimeLog: function (id) {
      const appId = this.$route.params.id;
      this.archiveTimeLogLoading = true;

      this.$store
        .dispatch("workload/deleteLogTime", {
          appId,
          id,
        })
        .then(() => {
          this.archiveTimeLogData = null;
          this.archiveTimeLogLoading = false;
          this.archiveTimeLogDialog = false;
        });
    },

    setupBreadcrumbs: function (name) {
      this.breadcrumbs = [
        {
          text: "Workload",
          disabled: false,
          exact: true,
          to: {
            name: "module-workload-tasks",
          },
        },
        {
          text: "Work Blocks",
          disabled: false,
          exact: true,
          to: {
            name: "module-workload-all",
          },
        },
        {
          text: name,
          disabled: true,
        },
      ];
    },
  },
};
</script>
